<template>
    <div v-if="shareModalVisible"
         :class="{ 'modal-bottom fade show': shareModalVisible }"
         id="ModalFiltersForm"
         tabindex="-1"
         style="display: block;"
         aria-modal="true"
         role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1>
                        <font-awesome-icon icon="fa-solid fa-share-nodes" class="mr-5" />
                        {{ $t('share.shareTitle') }}
                    </h1>
                    <a class="btn-close" @click="$emit('close')">
                        <font-awesome-icon icon="fa-solid fa-xmark" />
                    </a>
                </div>
                <div class="modal-body">
                    <p v-if="item != null" class="font-base color-gray-500 mb-20">
                        {{ item.titles[$i18n.locale.toUpperCase()] }}
                    </p>
                    <ul class="share-list">
                        <li>
                            <a @click="copyToClipboard" title="">
                                <font-awesome-icon :icon="['fas', showCopyCheck ? 'check' : 'copy']" class="mr-5" />
                                {{ $t('share.toClipbord') }}
                            </a>
                        </li>
                        <li>
                            <a :href="whatsappShareUrl" target="_blank" title="Share on WhatsApp">
                                <font-awesome-icon icon="fa-brands fa-whatsapp" class="mr-5" />
                                {{ $t('share.onWhatsapp') }}
                            </a>
                        </li>
                        <li>
                            <a :href="facebookShareUrl" target="_blank" title="Share on Facebook">
                                <font-awesome-icon :icon="['fab', 'facebook']" class="mr-5" />
                                {{ $t('share.onFacebook') }}
                            </a>
                        </li>
                        <li>
                            <a :href="twitterShareUrl" target="_blank" title="Share on X">
                                <font-awesome-icon :icon="['fab', 'x-twitter']" class="mr-5" />
                                {{ $t('share.onTwitter') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            shareModalVisible: Boolean,
            item: Object,
            shareText: String,
            shareUrl: String
        },
        data() {
            return {
                showCopyCheck: false
            };
        },
        computed: {
            whatsappShareUrl() {
                return `https://wa.me/?text=${encodeURIComponent(this.shareText + ' ' + this.shareUrl)}`;
            },
            facebookShareUrl() {
                return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareUrl)}`;
            },
            twitterShareUrl() {
                return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareText)}&url=${encodeURIComponent(this.shareUrl)}`;
            }
        },
        methods: {
            copyToClipboard() {
                navigator.clipboard.writeText(this.shareText + "\n\n" + this.shareUrl).then(() => {
                    this.showCopyCheck = true;
                    setTimeout(() => {
                        this.showCopyCheck = false;
                    }, 2000);
                });
            }
        }
    };
</script>

<style scoped>
    .modal-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        /*background: white;*/
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .share-list {
        list-style: none;
        padding: 0;
    }

        .share-list li {
            margin-bottom: 10px;
        }
</style>

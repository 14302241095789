<script src="./listingscomponent.js"></script>

<template>
    <div>
        <div id="navigationSection"
             style="border:1px solid #dadada;border-radius: 4px; text-align:center; padding: 4px;"
             v-if="listings.length > 0 && showNavigation">
            <div class="row ">
                <div class="d-none d-md-block col-md-3">
                    &nbsp;
                </div>
                <div class="col-md-3 col-sm-12 text-center" v-if=" pagination.total > 27">
                    <nav id="topNavigation" class=" d-inline-flex">
                        <ul class="pagination">
                            <li v-if="pagination.page > 1" class="page-item"><a class="" v-on:click="loadPage(1)">&laquo;</a></li>
                            <li v-if="pagination.page > 1" class="page-item"><a class="" v-on:click="loadPage(pagination.page-1)">&lt;</a></li>

                            <!-- page 1 -->
                            <li class="page-item">
                                <a class=""
                                   v-if="(pagination.page < 3) "
                                   v-bind:class="{active: pagination.page == 1}"
                                   v-on:click="loadPage(1)">1</a>
                                <!--v-if="!(pagination.page == (Math.ceil(this.pagination.total / 24) ))"-->
                            </li>

                            <!-- numbered previous page link -->
                            <li v-if="pagination.page > 2" class="page-item">
                                <a class="page-link2" v-on:click="loadPage(pagination.page -1)">{{pagination.page -1 }}</a>
                            </li>

                            <!-- active page link -->
                            <li v-if="pagination.page > 1 " class="page-item ">
                                <!--&& !(pagination.page  >= Math.ceil(this.pagination.total / 24))-->
                                <a class="page-link2 active">{{pagination.page }}</a>
                            </li>

                            <!-- numbered next page link -->
                            <li class="page-item" v-if="!(pagination.page  >=(Math.ceil(this.pagination.total / 24) ))">
                                <a class="page-link2" v-on:click="loadPage(pagination.page +1)">{{pagination.page +1 }}</a>

                            </li>

                            <!-- next page link -->
                            <li class="page-item"
                                v-if="!(pagination.page  >=(Math.ceil(this.pagination.total / 24)))">
                                <a class="page-link2 " v-on:click="loadPage(pagination.page+1)">&gt;</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-12 col-md-3  text-lg-end text-center">
                    <span v-if="activeCategory" class="font-sm color-gray-900 font-medium span itemCountOverview">
                        Advertenties {{pagination.page*listings.length-listings.length+1}} - {{pagination.page*listings.length}} van {{pagination.total}}
                    </span>
                </div>
            </div>
        </div>
        

        <div class="row" v-if="listings.length == 0 && showNavigation">
            <!--v-if="(pagination.total /24) > 1"-->
            <div class="col-12">
                <div class="no-listings mt-10">
                    Geen advertenties gevonden
                </div>
            </div>
        </div>
        <div class="border-bottom pt-10 mb-10" v-if="showNavigation"></div>

        <div class="row listings "  v-if="listings.length > 0 ">

            <template v-for="(listing, index) in listings" :key="listing.id">

                <div v-if="index > 0 && index % 9 == 0">
                    <AdvertComponent />

                </div>

                <router-link :to="{ name: listingDetailName, params: { id: listing.key }}"
                             :class="width == 'standard' ? 'col-12 col-md-6' : 'col-12' ">



                    <div class="card-vendor">
                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-3">
                                <img class="itemimage"
                                     width="100"
                                     height="100"
                                     :src="$defaultImage"
                                     :style="listing.imageUrls != null ? 'background-image: url(' + listing.imageUrls[0] +')' : ''" />

                                <span class="fav" v-if="user != null && user.favoriteListingsIds != null && user.favoriteListingsIds.includes(listing.id)">
                                    <font-awesome-icon icon="fa-solid fa-star" />
                                </span>
                            </div>

                            <div class="col-8 col-sm-8 col-md-9">
                                <span class="listingTitle mb-10"><b>{{listing.title}}</b></span>
                                <p class="listingDescription">
                                    <span><font-awesome-icon icon="fa-solid fa-tag" class="mr-5" /> &euro; {{listing.askingPrice}}</span> <br />

                                    <span v-if="listing.user != null && !showAsMgmnt">
                                        <font-awesome-icon icon="fa-regular fa-user" class="mr-5" /> {{listing.user.name}}
                                    </span>
                                    <span v-if="showAsMgmnt" class="font-xs color-gray-500 mt-10">Aangemaakt {{$filters.formatDate(listing.createdAt)}}</span>
                                </p>
                                <!--display on desktop-->
                                <div class=" d-none d-sm-block">
                                    <div class="border-bottom pt-10 mb-10"></div>
                                    <span class="btn btn-gray" style="margin-right:10px;">{{listing.itemCount}} Items</span>
                                    <span class="font-xs color-gray-500 mt-10">Aangemaakt {{$filters.formatDate(listing.createdAt)}}</span>
                                </div>

                            </div>
                        </div>
                        <!--display on mobile-->
                        <div class=" col-12 text-end d-block d-sm-none" v-if="!showAsMgmnt">
                            <div class="border-bottom pt-10 mb-10"></div>
                            <span class="font-xs color-gray-500 mt-10">Aangemaakt {{$filters.formatDate(listing.createdAt)}}</span>
                            <span class="btn btn-gray" style="margin-left:10px;">{{listing.itemCount}} Items</span>

                        </div>
                    </div>
                </router-link>
            </template>
        </div>

        <span ref="bottomNav" />

        <div id="navigationSection"
             style="border:1px solid #dadada;border-radius: 4px; text-align:center; padding: 4px;"
             v-if="showNavigation && (pagination.total /20) > 1">
            <div class="row">
                <div class="row">
                    <div class="col-12 text-center">
                        <nav id="topNavigation" class=" d-inline-flex">
                            <ul class="pagination">
                                <li v-if="pagination.page > 1" class="page-item"><a class="" v-on:click="loadPage(1)">&laquo;</a></li>
                                <li v-if="pagination.page > 1" class="page-item"><a class="" v-on:click="loadPage(pagination.page-1)">&lt;</a></li>

                                <!-- page 1 -->
                                <li class="page-item">
                                    <a class=""
                                       v-if="(pagination.page < 3)"
                                       v-bind:class="{active: pagination.page == 1}"
                                       v-on:click="loadPage(1)">1</a>
                                    <!--v-if="!(pagination.page == (Math.ceil(this.pagination.total / 24) ))"-->
                                </li>

                                <!-- numbered previous page link -->
                                <li v-if="pagination.page > 2" class="page-item">
                                    <a class="page-link2" v-on:click="loadPage(pagination.page -1)">{{pagination.page -1 }}</a>
                                </li>

                                <!-- active page link -->
                                <li v-if="pagination.page > 1 " class="page-item ">
                                    <!--&& !(pagination.page  >= Math.ceil(this.pagination.total / 24))-->
                                    <a class="page-link2 active">{{pagination.page }}</a>
                                </li>

                                <!-- numbered next page link -->
                                <li class="page-item" v-if="!(pagination.page  >=(Math.ceil(this.pagination.total / 24) ))">
                                    <a class="page-link2" v-on:click="loadPage(pagination.page +1)">{{pagination.page +1 }}</a>

                                </li>

                                <!-- next page link -->
                                <li class="page-item"
                                    v-if="!(pagination.page  >=(Math.ceil(this.pagination.total / 24)))">
                                    <a class="page-link2 " v-on:click="loadPage(pagination.page+1)">&gt;</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="showFiltersModal" class="modal fade show" id="ModalFiltersForm" tabindex="-1" style="display: block;" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-xl">
                <div class="modal-content apply-job-form">
                    <div class="modal-header">
                        <h5 class="modal-title color-gray-1000 filters-icon">Filters</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" v-on:click="showFiltersModal = false" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-30">
                        <div class="row">
                            <div class="col-w-1">
                                <h6 class="color-gray-900 mb-0">Locatie</h6>
                                Postcode <input v-on:change="applyFilter()" type="number" v-model="filter.postcode" />
                                <br />
                                Afstand <input v-on:change="applyFilter()" type="range" v-model="rangeSelector" step="1" max="6" id="tempB" name="temp" list="values" />

                                <span>{{filter.rangeInKm}}</span>

                                <datalist id="values">
                                    <option value="1" label="very cold!"></option>
                                    <option value="2" label="cool"></option>
                                    <option value="3" label="medium"></option>
                                    <option value="4" label="medium"></option>
                                    <option value="5" label="getting warm!"></option>
                                    <option value="6" label="hot!"></option>
                                </datalist>

                                <label class="cb-container">
                                </label>
                            </div>
                            <div class="col-w-1"
                                 v-for="aggregate in this.aggregates"
                                 v-bind:key="aggregate.key">

                                <h6 class="color-gray-900 mb-0">{{aggregate.field}}</h6>
                                <ul class="list-checkbox">
                                    <li v-for="aggregateValue in aggregate.values" v-bind:key="aggregateValue.key">
                                        <label class="cb-container">
                                            <input v-on:change="searchFilters(aggregate.field, aggregateValue.key, $event)"
                                                   type="checkbox"
                                                   :checked="isFilterApplied(aggregate.field, aggregateValue.key)">
                                            <span class="text-small">{{aggregateValue.key}} ({{aggregateValue.count}})</span>
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-start pl-30">
                        <a class="btn btn-buy w-auto" v-on:click="showFiltersModal = false">Toon {{pagination.total}} resultaten</a>
                        <a class="btn font-sm-bold color-gray-500" v-on:click="resetFilters()">Reset Fillter</a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showFiltersModal" class="modal-backdrop fade show"></div>


    </div>
</template>


<style scoped>
    /* .itemImage {
        border-radius: 4px;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #dadada;
        border-radius: 4px;
        background-size: cover;
    }*/

    .navigation {
        border-radius: 4px;
        border: 1px solid #dadada;
        /*background-color: white;*/
    }

    .fav {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
    }

    .no-listings {
        border-radius: 4px;
        border: 1px solid #dadada;
        /*background-color: white;*/
        padding: 10px;
        text-align: center
    }
    /* .listings:first-child {
        margin-top:0px;
    }*/

    .listingTitle {
        font-size: 1.1em;
        display: block;
    }

    .listingDescription {
        font-size: 1em;
        /*color: darkslategray;*/
    }

    .paginationInfo {
        /*color: darkslategray;*/
    }

    .itemCountOverview {
        display: block;
        padding-top: 6px;
        padding-bottom: 6px;
    }
</style>
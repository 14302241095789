<template>
    <div class="" v-if="posts != null">
        <div class="border-bottom">
            <h1>{{ $t('blog.blog') }}</h1>
        </div>
        <div v-if="posts.length === 0" class="no-posts">No blog posts available.</div>
        <div v-for="post in posts" :key="post.id" class="post-card">
            <div class="post-content">
                <h2 class="blogTitle border-bottom">
                    {{post.titles[this.$i18n.locale.toUpperCase()]}}
                </h2>
                <div class="blogContent" v-html="post.contents[this.$i18n.locale.toUpperCase()]"></div>
                <div class="text-end createdAt">
                    {{$filters.formatDayMonthYear(post.createdAt)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import httpClient from '@/httpClients/httpClient';

    const apiUrl = "/blogpost";
    export default {
        name: 'wishlist',
        data() {
            return {
                posts: null,
            }
        },
        methods: {
            async fetchPosts() {
                try {
                    const response = await httpClient.get(apiUrl);
                    this.posts = response.data;
                } catch (error) {
                    console.error("Error fetching posts:", error);
                }
            }
        },
        mounted() {
            this.fetchPosts();
        }
    }

</script>

<style scoped>
    .blogTitle {
        font-size: 1.1em;
        font-weight: bold;
    }

    .container {
        max-width: 800px;
        margin: auto;
        padding: 20px;
    }

    .no-posts {
        text-align: center;
    }

    .post-card {
        display: flex;
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        margin-top: 15px;
        padding-right: 20px;
    }

    .post-image {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 15px;
    }

   

    .blogContent {
        padding-top: 10px;
    }

    .post-content {
        width: 100%;
    }
    .createdAt{
        font-size:0.9em;
        font-style:italic;
    }

    :deep(p) {
        margin-bottom: 10px !important;
        display: block;
    }
    :deep(a){
        text-decoration: underline;
        font-weight:bold;
    }
</style>

import { createApp } from 'vue'
import App from './components/app.vue'
import router from './components/router'
//import moment from "moment";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { createI18n } from 'vue-i18n'

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faCopy, faWhatsapp, faFacebook, faXTwitter);


import nl from './locales/nl.json';
import de from './locales/de.json';
import en from './locales/en.json';

const app = createApp(App)

app.config.globalProperties.$prevRouteUrl = '';
app.config.globalProperties.$prevRouteName = '';
app.config.globalProperties.$platform = '';

app.config.globalProperties.$defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

if (location.href.startsWith("https://rangeerspoor.nl")) {
    app.config.globalProperties.$platform = 'Rangeerspoor.nl'
} else {
    app.config.globalProperties.$platform = 'Traincollector.net'

}

app.config.globalProperties.$setRoutingName = function (url, name) {
    app.config.globalProperties.$prevRouteUrl = url;
    app.config.globalProperties.$prevRouteName = name;
}

app.config.globalProperties.$filters = {
    formatDate(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based

        return `${day}-${month}`;

    },
    formatDayMonthYear(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear());

        return `${day}-${month}-${year}`;

    },
    formatDateTime(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month} ${hours}:${minutes}`;
    }
}

library.add(fas)
library.add(far)

app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)

var lang = localStorage.getItem("lang");

var userLang = navigator.language || navigator.userLanguage;
if (lang == null) {
    lang = userLang;
}

const i18n = createI18n({
    globalInjection: true,
    locale: lang,
    fallbackLocale: 'nl',
    messages: {
        nl,
        de,
        en        
    }
})

app.use(i18n)
app.config.globalProperties.$x = i18n.global.t


app.mount('#app')
